export enum ActiveFeatureFlags {
  CREDENTIAL_FORM = 'credential_form',
  MFA_ISSUER = 'mfa_issuer',
  EMAIL_CAMPAIGNS = 'email_campaigns',
  EMAIL_CAMPAIGN_DELIVERY_CONDITION = 'email_campaign_delivery_condition',
  DEFAULT_CAMPAIGNS_RELEASE = 'default_campaigns_release',
  CANVAS_INTEGRATION_LTI_V1P3 = 'canvas_integration_lti_v1p3',
  BRIGHTSPACE_INTEGRATION_LTI_V1P3 = 'brightspace_integration_lti_v1p3',
  SKILLJAR_CUSTOM_ATTRIBUTES = 'skilljar_integration_custom_attributes',
  SKILLJAR_NEW_UX = 'skilljar_integration_new_ux',
  BRIGHTSPACE_CUSTOM_ATTRIBUTES = 'brightspace_integration_custom_attributes',
  BENCHPREP_INTEGRATION = 'benchprep_integration',
  RICH_TEXT_GROUP_ORG_SETTINGS = 'rich_text_group_org_settings',
  V2_EMAIL_EDITOR = 'v2_template_editor',
  V2_EMAIL_ANALYTICS = 'email_analytics_v2',
}

export type FeatureFlag = {
  [K in ActiveFeatureFlags]: boolean;
};
